<template>
  <Layout ref="main">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-title p-3 py-2 border-bottom">
            <div class="d-lg-flex align-items-center">
              <div class="me-2 d-inline-block"><i class="uil uil-lightbulb-alt display-6 text-primary"></i></div>
              <div class="mb-2 mb-lg-0 d-inline-block">
                  <h4 class="fw-medium font-size-20 text-dark mb-0"> {{ title }}</h4>
                  <small class="d-block font-size-13 text-muted fw-normal">Small Description</small>
              </div>

              <div class="ms-auto">
                <button class="btn btn-outline-info fw-medium me-2">
                  <i class="uil uil-plus font-size-16 me-1"></i> Button
                </button>
              </div>
            </div>
          </div>
          
          <div class="card-body">
            <div class="text-center">
              <div
                class="spinner-border text-secondary my-5"
                v-if="loading"
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Lottie :path="'animate/loading_purple.json'" :title="'Loading'" :info="'Please wait...'" :show="showLottie" ref="lottieFunc" />
    <Common ref="commonFunc"/>
  </Layout>
</template>
<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
import {required } from "vuelidate/lib/validators";
export const equal2A = (value) => {
  return value !== 'YOUR_API_ID';
}
export const equal2B = (value) => {
  return value !== 'YOUR_API_HASH';
}

/**
 * Starter page
 */
 const PageTitle = "Starter"

export default {
  components: { 
    Layout, 
    PageHeader, 
    Lottie,
    Common
  },
  page() {
    return {
      title: PageTitle,
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    }
  },
  data() {
    return {
      accessToken:'',
      accessEmail:'',
      accessPhone:'',
      accessUsername:'',
      account_type:'',
      create_modify_permission:false,
      extraInfo: {
        payment_gateway_name: "",
      },
      showLottie:false,
      title: '',
      items: [
        {
          text: appConfig.pageTitle,
          to: "/",
        },
        {
          text: '',
          active: true,
        },
      ],
      formData:{
        apiID: "YOUR_API_ID",
        apiHash:"YOUR_API_HASH",
      },
      submitted:false,
      loading:false,
      buttonLoading:[false,false,false],
    };
  },
  validations: {
    formData: {
      apiID: {
        required,
        equal2A
      },
      apiHash: {
        required,
        equal2B
      },
    }
  },
  middleware: "authentication",
  async mounted(){
    this.title = PageTitle
    this.items[1].text = PageTitle
    // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
    // this.$refs.main.changeVerticalTopBar("bill",true)
    // this.$refs.main.setShowFooterCert(false)
    // this.$refs.main.setPageTitle('title')

    await this.reload();
    this.accessToken = this.$refs.commonFunc.getToken()
    this.accessUsername = this.$refs.commonFunc.getUsername()
    await this.getData();
    
  }, 
  created(){
   
  },
  methods:{
    async getData(){
        this.$Progress.start();
        this.loading = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("phone",  this.accessPhone);
        axios({
            method: "post",
            url: appConfig.DemoAPI,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              this.reload()
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    save() {
        this.submitted = true
        this.$v.formData.$touch();
        if (this.$v.formData.$invalid) {
          return;
        } else {
            this.$Progress.start();
            this.loading = true
            this.buttonLoading[0] = true
            //bodyFormData.append("accessToken", this.accessToken);
            const datas = {
              phone: this.accessPhone,
              apiID: this.formData.apiID,
              apiHash: this.formData.apiHash,
            };


            const sendRequest = async () => {
              try {
                //  await new Promise((resolve) => {
                //    resolve();
                //  });

                const response = await 
                axios.post('https://127.0.0.1:5000/setting', 
                  JSON.stringify(datas), {
                    headers: { 'Content-Type': 'application/json' },
                  });
                if(response){
                  var resData = response.data;
                  if (resData.status == 200) {
                      console.log(resData)
                      Swal.fire({
                        icon: 'success',
                        title: 'Saved',
                        html: `API ID and API Hash have been successfully saved!`,
                        confirmButtonColor: '#FA6541',
                        confirmButtonText: this.$t('siteLang.Done')
                      })
                      const updateData = {
                        "apiID": this.formData.apiID,
                        "apiHash": this.formData.apiHash,
                    }
                    this.$refs.commonFunc.updateData(JSON.stringify(updateData))
                    this.reload()
                  }
                  if (resData.status == 500) {
                    console.log(resData)
                    Swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      html: resData.message,
                      confirmButtonColor: '#FA6541',
                      confirmButtonText: this.$t('siteLang.Done')
                    })
                  }
                  this.formData.group_name=""
                  this.formData.users= null

                  this.loading = false;
                  this.submitted = false
                  this.buttonLoading[0] = false
                  this.$Progress.finish();
                }
                } catch (error) {
                  this.buttonLoading[0] = false
                  this.loading = false;
                  this.$Progress.finish();
                  //Swal.fire("Error", error, "error");
                  Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${error}.`,
                  confirmButtonColor: '#FA6541',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                }
            };

            sendRequest();
        }
    },
    
    reset() {
        this.submitted = true
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        } else {
            this.$Progress.start();
            this.loading = true
            this.buttonLoading[1] = true
            var bodyFormData = new FormData();
            bodyFormData.append("accessToken", this.accessToken);
            bodyFormData.append("phone",  this.accessPhone);
            axios({
                method: "post",
                url: appConfig.DemoAPI,
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then((response)=>{
                var resData = response.data;
                if (resData.status == 200) {
                  console.log(resData)
                  Swal.fire({
                    icon: 'success',
                    title: 'Deleted',
                    html: `API ID and API Hash have been successfully deleted and reset!`,
                    confirmButtonColor: '#FA6541',
                    confirmButtonText: this.$t('siteLang.Done')
                  })
                  const updateData = {
                    "apiID": 'YOUR_API_ID',
                    "apiHash": 'YOUR_API_HASH',
                 }
                 this.$refs.commonFunc.updateData(JSON.stringify(updateData))
                 this.reload()
                   
                } 
                else if (resData.status == 440){
                  Swal.fire(
                      {
                      icon: 'error',
                      title: 'Oops...',
                      html: `${resData.message}.`,
                      confirmButtonColor: '#FA6541',
                      confirmButtonText: this.$t('siteLang.Done'),
                    })
                    localStorage.clear();
                    this.$router.push({
                        path: "/login",
                  });
                }
                else {
                    Swal.fire(
                      {
                      icon: 'error',
                      title: 'Oops...',
                      html: `${resData.message}.`,
                      confirmButtonColor: '#FA6541',
                      confirmButtonText: this.$t('siteLang.Done'),
                    })
                }
                this.loading = false;
                this.submitted = false
                this.buttonLoading[1] = false
                this.$Progress.finish();
            })
            .catch((error)=> {
                this.buttonLoading[1] = false
                this.loading = false;
                this.$Progress.finish();
                //Swal.fire("Error", error, "error");
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${error}.`,
                  confirmButtonColor: '#FA6541',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
          });
        }
    },
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted

        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          this.account_type = data.account_type;
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },

  }
};
</script>